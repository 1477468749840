<template>
  <div>
    <DataTable
      v-model:filters="rowFilters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <template v-if="hasHeader" #header>
        <slot name="header"></slot>
      </template>
      <slot name="columns"></slot>

      <!-- Expansion slot for details -->
      <template #expansion="details">
        <DataTable
          :value="details.data.details"
          :scrollable="true"
          class="p-datatable-sm"
          showGridlines
        >
          <template #empty>
            <div class="text-center">Tidak ada data.</div>
          </template>
          <template #loading>
            <div class="text-center">Memuat data, harap tunggu...</div>
          </template>

          <!-- Columns for debit and kredit -->
          <Column field="coa_item.nama" header="perkiraan" />
          <Column field="keterangan" header="keterangan" />
          <Column
            field="debit"
            header="debit"
            style="
              text-align: right;
              min-width: 10rem;
              justify-content: flex-end;
            "
          >
            <template #body="slotProps">
              <span style="text-align: right">
                {{ formatCurrency(slotProps.data.debit) }}
              </span>
            </template>
          </Column>
          <Column
            field="kredit"
            header="kredit"
            style="
              text-align: right;
              min-width: 10rem;
              justify-content: flex-end;
            "
          >
            <template #body="slotProps">
              <span style="text-align: right">
                {{ formatCurrency(slotProps.data.kredit) }}
              </span>
            </template>
          </Column>

          <!-- Footer for total debit and kredit -->
          <ColumnGroup type="footer">
            <Row>
              <Column footer="Total:" :colspan="2" />
              <Column
                :footer="
                  formatCurrency(calculateTotal(details.data.details, 'debit'))
                "
                style="
                  text-align: right;
                  min-width: 10rem;
                  justify-content: flex-end;
                "
              />
              <Column
                :footer="
                  formatCurrency(calculateTotal(details.data.details, 'kredit'))
                "
                style="
                  text-align: right;
                  min-width: 10rem;
                  justify-content: flex-end;
                "
              />
            </Row>
          </ColumnGroup>
        </DataTable>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/index'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      rowFilters: this.filters,
      options: this.gridOptions,
    }
  },
  computed: {
    totalDebit() {
      if (this.items.details) {
        return this.formatCurrency(
          this.items.details.reduce(
            (total, item) => total + (item.debit || 0),
            0
          )
        )
      }
      return this.formatCurrency(0)
    },
    totalKredit() {
      if (this.items.details) {
        return this.formatCurrency(
          this.items.details.reduce(
            (total, item) => total + (item.kredit || 0),
            0
          )
        )
      }
      return this.formatCurrency(0)
    },
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.rowFilters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.rowFilters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    calculateTotal(details, field) {
      return details.reduce((total, item) => total + (item[field] || 0), 0)
    },
  },
}
</script>

<template>
  <Calendar
    v-model="value"
    selectionMode="range"
    dateFormat="dd/mm/yy"
    :showButtonBar="showButtonBar"
    :numberOfMonths="numberOfMonths"
    @date-select="mergeDate()"
    @clear-click="clearDate()"
  />
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    modelValue: {
      type: String,
      default() {
        return null
      },
    },
    showButtonBar: {
      type: Boolean,
      default: false,
    },
    numberOfMonths: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      oldValue: null,
      value: null,
      clearUpdate: true,
    }
  },
  watch: {
    value(value) {
      if ((value === null) & this.clearUpdate) {
        this.clearDate()
      } else if (value && value[1] !== null) {
        this.mergeDate()
      }
    },
  },
  methods: {
    mergeDate() {
      if (this.value[1] !== null) {
        let dates = []
        this.value.forEach((el) => {
          dates.push(dayjs(el).format('YYYY-MM-DD'))
        })
        if (this.oldValue != dates.join(',')) {
          this.$emit('update:modelValue', dates.join(','))
          this.$emit('select')
          this.oldValue = dates.join(',')
        }
      }
    },
    clearDate() {
      this.clearUpdate = true
      this.oldValue = null
      this.value = null
      this.$emit('update:modelValue', '')
      this.$emit('select')
    },
    clearValueOnly() {
      this.clearUpdate = false
      this.oldValue = null
      this.value = null
    },
  },
}
</script>
